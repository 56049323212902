import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useVenue from "../../hooks/useAllVenues";
import "./ReservationPage.css"; // Make sure to create this CSS file
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { Hidden } from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useParams } from "react-router-dom";
import { Check, ChevronLeft, Close, Person2 } from "@mui/icons-material";
import useIsMobile from "../../hooks/useIsMobile";
import VerifyBooking from "../../components/VerifyBooking/VerifyBooking";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  VALET_CHECKING,
  CURRENCY,
  ADD_GUESTS,
  OTP_VERIFICATION,
  SHOW_BANNER,
  VALET_DEPOSIT,
  NOTES,
  CHILD_CHECKING,
  SEARCH_PHONE_NUMBER,
} from "../../constants/Constants";
import BookingOTP from "../../components/BookingOTP/BookingOTP";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import useApp from "../../hooks/useApp";
import BookingStatus from "../../components/BookingStatus/BookingStatus";
import { Screens } from "../../constants/enums";
import Banner from "../../components/Banner/Banner";
import { useSelector } from "react-redux";
import { GetUserService } from "../../services/GetUserService";

const theme = createTheme(lightThemeOptions);

const afterAWeek = () => {
  const today = new Date();
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  return nextWeek.toISOString().split("T")[0];
};

function ReservationPage() {
  const {
    control,
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange", // Trigger validation on change
    shouldFocusError: false,
  });

  const { venue, venueLoading, venueError } = useVenue();

  const params = useParams();
  const origin = params.source || "default";
  const { web } = useIsMobile();
  const { showError } = useApp();

  const [isValet, setIsValet] = useState(false);
  const [persons, setPersons] = useState("1"); // Initial value of persons
  const [children, setChildren] = useState("0"); // Initial value of children
  const [area, setArea] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [deposit, setDeposit] = useState<any>(0);
  const [childDeposit, setChildDeposit] = useState<any>(0);
  const [totalPay, setTotalPay] = useState<any>(0);
  const [guest, setGuest] = useState<string>("");
  const [guests, setGuests] = useState<string[]>([]);
  const [addGuestList, setAddGuestList] = useState(false);
  const [page, setPage] = useState(Screens.BOOKING_FORM); // Screens.BOOKING_FORM
  const [booking, setBooking] = useState({
    area: "",
    date: "",
    email: "",
    mobile: "",
    name: "",
    persons: "",
    children: "",
    time: "",
  });
  const isDarkMode = useSelector((state: any) => state.app.darkMode);

  const [userDataLoading, setUserDataLoading] = useState(false);

  useEffect(() => {
    if (venue && venue.eventDeposit) {
      setDeposit(venue.eventDeposit);
      setChildDeposit(venue.childDeposit);

      let valetDeposit = isValet ? VALET_DEPOSIT : 0;

      const adultTotal = +persons * venue.eventDeposit;
      const childTotal = +children * venue.eventDeposit;
      setTotalPay(adultTotal + childTotal + valetDeposit);
    }
  }, [venue]);

  useEffect(() => {
    if (+persons > 0) {
      let valetDeposit = isValet ? VALET_DEPOSIT : 0;
      const adultTotal = +persons * deposit;
      const childTotal = +children * childDeposit;

      setTotalPay(adultTotal + childTotal + valetDeposit);
    } else {
      setTotalPay(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons, children, isValet]);

  useEffect(() => {
    async function getUserData(mobile: string) {
      setUserDataLoading(true);
      const users = await GetUserService(mobile);

      if (users && users.length > 0) {
        const userName = users[0].name;
        const userEmail = users[0].email;

        setValue("name", userName);
        setValue("email", userEmail);
      }
      setUserDataLoading(false);
    }

    if (SEARCH_PHONE_NUMBER) {
      const regex = /^(01[0-2]|015|05)\d{8}$/;
      const phoneNumber = getValues("mobile");

      // run function only if the mobile number is valid
      if (regex.test(phoneNumber)) {
        getUserData(phoneNumber);
      } else {
        // reset the name and email fields if the mobile number is invalid
        setValue("name", "");
        setValue("email", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("mobile")]);

  const handleGuestList = () => {
    setAddGuestList(!addGuestList);
  };
  const handleGuestChange = (event: any) => {
    setGuest(event.target.value);
  };

  const handleAddGuest = () => {
    if (guests.length >= +persons) {
      showError("You have exceeded the guest number!");

      return;
    }
    if (guest && guest.length > 0) {
      setGuests((prevGuests) => [...prevGuests, guest]);
      setGuest("");
    }
  };

  const handleRemoveGuest = (index: number) => {
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests.splice(index, 1);
      return updatedGuests;
    });
  };

  const validateFirstPage = async () => {
    let result;
    if (+persons <= 0) {
      showError("Please enter a valid person number.");
      return;
    }
    if (area && time) {
      result = await trigger(["date"]); // Replace with your actual field names
    } else if (area) {
      result = await trigger(["date", "time"]); // Replace with your actual field names
    } else if (time) {
      result = await trigger(["date", "area"]); // Replace with your actual field names
    } else result = await trigger(["date", "time", "area"]); // Replace with your actual field names

    if (result) {
      setAddGuestList(false);
      setPage(Screens.BOOKING_GUEST_INFO);
    }
  };
  const [fieldWidth, setFieldWidth] = useState("100%");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const BookingFields = [
    {
      name: "date",
      label: "Date",
      sx: { width: { xs: "18rem", sm: fieldWidth } },
      size: "small",
      InputProps: {
        sx: { borderRadius: "10px" },
      },
      inputProps: {
        min: new Date().toISOString().split("T")[0],
        max: afterAWeek(),
      },
      margin: "normal",
      type: "date",
      register: {
        ...register("date", {
          required: "Date is required",
          min: {
            value: new Date().toISOString().split("T")[0],
            message: "Date should be after today",
          },
          max: {
            value: afterAWeek(),
            message: "Date should be within a week",
          },
        }),
      },
      InputLabelProps: { shrink: true },
      autoComplete: false,
    },
    {
      name: "time",
      label: "Time",
      sx: { width: { xs: "18rem", sm: fieldWidth } },
      size: "small",
      InputProps: { sx: { borderRadius: "10px" } },
      margin: "normal",
      type: "default",
      register: {
        ...register("time", { required: "Time is required" }),
      },
      InputLabelProps: {},
      autoComplete: true,
    },
    // {
    //   name: "area",
    //   label: "Area",
    //   sx: { width: { xs: "18rem", sm: fieldWidth } },
    //   size: "small",
    //   InputProps: { sx: { borderRadius: "10px" } },
    //   margin: "normal",
    //   type: "default",
    //   register: {
    //     ...register("area", { required: "Area selection is required" }),
    //   },

    //   InputLabelProps: {},
    //   autoComplete: true,
    // },
  ];
  const userFields = [
    {
      name: "mobile",
      label: "Mobile",
      sx: { width: { xs: "18rem", sm: fieldWidth } },
      size: "medium",
      InputProps: {
        sx: { borderRadius: "10px" },
        endAdornment: <>{userDataLoading && <CircularProgress />}</>,
      },
      margin: "normal",
      type: "number",
      register: {
        ...register("mobile", {
          required: "mobile is required",
          pattern: {
            // value: /^(010|011|012|015)\d{8}$/, // Egypt only
            value: /^(01[0-2]|015|05)\d{8}$/, // To accept KSA numbers also
            message: "Enter a valid mobile",
          },
        }),
      },
      InputLabelProps: {},
      autoComplete: false,
      autoCompleteProps: {},
    },
    {
      name: "name",
      label: "Name",
      sx: { width: { xs: "18rem", sm: fieldWidth } },
      size: "medium",
      InputProps: { sx: { borderRadius: "10px" } },
      margin: "normal",
      type: "default",
      register: {
        ...register("name", {
          required: "Name is required",
        }),
      },
      InputLabelProps: { shrink: Boolean(getValues("name")) },
      autoComplete: false,
      autoCompleteProps: {},
    },

    {
      name: "email",
      label: "Email",
      sx: { width: { xs: "18rem", sm: fieldWidth } },
      size: "medium",
      InputProps: { sx: { borderRadius: "10px" } },
      margin: "normal",
      type: "email",
      register: {
        ...register("email", {
          required: "Email is required",
          pattern: {
            value: /^\S+@\S+\.\S+$/,
            message: "Enter a valid email",
          },
        }),
      },
      InputLabelProps: { shrink: Boolean(getValues("email")) },
      autoComplete: false,
      autoCompleteProps: {},
    },
  ];

  function getAutoCompleteOptions(item: any) {
    if (item.name === "area") {
      return venue.areas;
    } else if (item.name === "time") {
      return venue.timeSlots.map(
        (item: any) => item.friendlyName + " (" + item.name + ")"
      );
    } else {
      return [];
    }
  }

  const handleData = (name: any, data: any) => {
    if (name === "area") {
      setArea(data);
    }
    if (name === "time") {
      setTime(data);
    }
  };

  const onSubmit = (data: any) => {
    const foundSlot = venue.timeSlots.find((slot: any) => {
      const dataToCompare = slot.friendlyName + " (" + slot.name + ")";
      return dataToCompare === data.time;
    });

    const id = foundSlot ? foundSlot.id : "";
    const updatedData = {
      ...data,
      time: id,
      isValet,
      persons: persons,
      children: children,
    };
    setBooking(updatedData);

    if (OTP_VERIFICATION) {
      setPage(Screens.BOOKING_OTP);
    } else {
      setPage(Screens.BOOKING_CONFIRMATION);
    }

    // Reset Form
    resetForm();
  };

  const resetForm = () => {
    reset();
    setPersons("1");
    setChildren("0");
    setArea(null);
    setTime(null);
  };

  // Adults
  const handleIncrement = () => {
    setPersons((+persons + 1).toString());
  };

  const handleDecrement = () => {
    if (+persons > 1) {
      setPersons((+persons - 1).toString());
    }
  };

  // Children
  const handleChildrenIncrement = () => {
    setChildren((+children + 1).toString());
  };

  const handleChildrenDecrement = () => {
    if (+children > 0) {
      setChildren((+children - 1).toString());
    }
  };

  if (venueLoading || venue === null) {
    return (
      <>
        <Hidden mdUp>
          <SplashScreen venue={venue} />
        </Hidden>

        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      </>
    );
  } else if (venueError) return <Alert severity="error">{venueError}</Alert>;
  else if (venue)
    return (
      <div
        style={
          !web
            ? {
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                flexDirection: !web ? "column" : "row",
                position: "relative",
                width: "100%",
              }
            : {
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                flexDirection: !web ? "column" : "row",
                position: "relative",
                width: "100%",
                height: "100vh",
                overflow: "hidden",
              }
        }
      >
        <Grid container spacing={!web ? 0 : 2}>
          {web && (
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={
                    venue.image
                      ? venue.image
                      : require("../../assets/taghmisa.webp")
                  }
                  alt="Venue"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={8}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            {/* Concept Logo */}
            {venue && venue.logo && !web && (
              <img
                src={venue.logo}
                // src={
                //   isDarkMode
                //     ? require("../../assets/taghmisaLogoWhite.webp")
                //     : require("../../assets/taghmisaLogo.webp")
                // }
                alt="Venue Logo"
                style={{
                  width: "200px",
                  height: "150px",
                  objectFit: "contain",
                  marginTop: "2rem",
                  zIndex: 2,
                }}
              />
            )}

            {/* Banner */}
            {SHOW_BANNER &&
              (page === Screens.BOOKING_FORM ||
                page === Screens.BOOKING_GUEST_INFO) && <Banner></Banner>}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "65%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {page === Screens.BOOKING_GUEST_INFO && (
                <ChevronLeft
                  style={{
                    color: "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => setPage(Screens.BOOKING_FORM)}
                />
              )}

              {page !== Screens.BOOKING_STATUS &&
                page !== Screens.BOOKING_CONFIRMATION && (
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      fontFamily: "Crimson Text",
                      fontWeight: 500,
                      flex: 1, // Allow title to take up remaining space
                    }}
                  >
                    Reserve a Table
                  </Typography>
                )}
            </div>

            {/* Booking Form */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "90%",
              }}
            >
              {page === Screens.BOOKING_FORM && (
                <>
                  {/* Adults Counter */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <button
                      color="primary"
                      style={{
                        ...styles.counterButton,
                        marginRight: "20px",
                      }}
                      onClick={handleDecrement}
                    >
                      <strong>-</strong>
                    </button>
                    <TextField
                      key={0}
                      label={
                        `Number Of Adults`
                        // deposit > 0
                        //   ? `Number Of Adults ( ${deposit} ${CURRENCY} per adult )`
                        //   : `Number Of Adults`
                      }
                      value={persons}
                      sx={{
                        width: fieldWidth,
                        marginRight: "20px",
                        borderRadius: "10px",
                      }}
                      size="small"
                      InputProps={BookingFields[0].InputProps}
                      type="number"
                      margin="normal"
                      onChange={(e) => setPersons(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "." || e.key === "e") e.preventDefault();
                      }}
                      onWheel={(e) => {
                        //@ts-ignore
                        e.target?.blur();
                      }}
                    />
                    <button
                      color="primary"
                      style={styles.counterButton}
                      onClick={handleIncrement}
                    >
                      <strong>+</strong>
                    </button>
                  </div>

                  {ADD_GUESTS && +persons > 1 && (
                    <>
                      <Button onClick={handleGuestList}>
                        {addGuestList ? "Remove Guest List" : " Add Guest List"}
                      </Button>

                      {addGuestList && page === Screens.BOOKING_FORM && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            flexWrap: "nowrap",
                            justifyContent: "center",
                            width: web ? "50%" : "70%",
                          }}
                        >
                          <TextField
                            label="Add Guest"
                            size="small"
                            value={guest}
                            onChange={handleGuestChange}
                            margin="normal"
                            type="default"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleAddGuest();
                              }
                            }}
                            InputProps={{
                              sx: { borderRadius: "10px" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Check
                                    onClick={handleAddGuest}
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            style={{
                              width: web ? "80%" : "100%",
                            }}
                          />

                          <List
                            dense={true}
                            style={{
                              maxHeight: "200px",
                              overflowY:
                                guests.length > 4 ? "scroll" : "hidden",
                              width: web ? "80%" : "100%",
                            }}
                            className={guests.length > 4 ? "scroll" : ""}
                          >
                            {guests.map((value, index) => (
                              <ListItem
                                secondaryAction={
                                  <IconButton edge="end" aria-label="delete">
                                    <Close
                                      onClick={() => handleRemoveGuest(index)}
                                    />
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <Avatar>
                                    <Person2 />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={value} />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      )}
                    </>
                  )}

                  {/* Children Section */}
                  {CHILD_CHECKING && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <button
                        color="primary"
                        style={{
                          ...styles.counterButton,
                          marginRight: "20px",
                        }}
                        onClick={handleChildrenDecrement}
                      >
                        <strong>-</strong>
                      </button>
                      <TextField
                        key={0}
                        label={
                          `Number Of Children`
                          // deposit > 0
                          //   ? `Number Of Children ( ${deposit} ${CURRENCY} per child )`
                          //   : `Number Of Children`
                        }
                        value={children}
                        sx={{
                          width: fieldWidth,
                          marginRight: "20px",
                          borderRadius: "10px",
                        }}
                        size="small"
                        InputProps={BookingFields[0].InputProps}
                        type="number"
                        margin="normal"
                        onChange={(e) => setChildren(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "e")
                            e.preventDefault();
                        }}
                        onWheel={(e) => {
                          //@ts-ignore
                          e.target?.blur();
                        }}
                      />
                      <button
                        color="primary"
                        style={styles.counterButton}
                        onClick={handleChildrenIncrement}
                      >
                        <strong>+</strong>
                      </button>
                    </div>
                  )}
                </>
              )}

              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  width: "90%",
                }}
              >
                {page === Screens.BOOKING_FORM && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {BookingFields.map((item: any, index: number) =>
                        item.autoComplete ? (
                          <Controller
                            key={index}
                            name={item.name}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                options={getAutoCompleteOptions(item)}
                                value={
                                  item.name === "area"
                                    ? area
                                    : item.name === "time"
                                    ? time
                                    : ""
                                }
                                onChange={(event, newValue) =>
                                  handleData(item.name, newValue)
                                }
                                style={{
                                  borderRadius: "10px",
                                }}
                                sx={{
                                  width: {
                                    xs: "18rem",
                                    sm: fieldWidth,
                                  },
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      key={index}
                                      label={item.label}
                                      size={item.size}
                                      sx={{
                                        "& .MuiAutocomplete-inputRoot": {
                                          borderRadius: "10px",
                                        },
                                        width: {
                                          xs: "18rem",
                                          sm: fieldWidth,
                                        },
                                      }}
                                      type={item.type}
                                      margin={item.margin}
                                      {...item.register}
                                      error={!!errors[item.name]}
                                      helperText={errors[
                                        item.name
                                      ]?.message?.toString()}
                                      InputLabelProps={item.InputLabelProps}
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        ) : (
                          <>
                            <TextField
                              key={index}
                              label={item.label}
                              value={item.value}
                              sx={item.sx}
                              size={item.size}
                              InputProps={item.InputProps}
                              inputProps={item.inputProps}
                              type={item.type}
                              margin={item.margin}
                              {...item.register}
                              onWheel={(e) => {
                                if (item.type === "number") {
                                  //@ts-ignore
                                  e.target?.blur();
                                }
                              }}
                              error={!!errors[item.name]}
                              helperText={errors[
                                item.name
                              ]?.message?.toString()}
                              InputLabelProps={item.InputLabelProps}
                            />
                          </>
                        )
                      )}
                    </div>

                    {/* Valet */}
                    {VALET_CHECKING && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={isValet}
                          onChange={(e) => setIsValet(e.target.checked)}
                        />
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Requires Valet
                        </Typography>
                        <Tooltip
                          title={`This service will add flat ${VALET_DEPOSIT} ${CURRENCY} to your booking total amount`}
                          placement="right-start"
                        >
                          <IconButton>
                            <InfoOutlinedIcon
                              sx={{
                                color: theme.palette.primary.dark,
                                fontSize: "20px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}

                    {/* Total Amount Calculator */}
                    {venue && venue.eventDeposit > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography sx={{ color: "#737170" }}>
                          Total amount : &nbsp;
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.dark }}>
                          {parseFloat(totalPay.toFixed(2))} {CURRENCY}
                        </Typography>
                      </Box>
                    )}

                    {/* List reservation notes */}
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      {NOTES.map((note, index) => (
                        <Typography
                          key={index}
                          sx={{
                            fontSize: "13px",
                            paddingLeft: "20px",
                          }}
                        >
                          {note}
                        </Typography>
                      ))}
                    </Box>
                  </>
                )}

                {page === Screens.BOOKING_GUEST_INFO && (
                  <>
                    {!isIOS &&
                      userFields.map((item: any, index: number) => (
                        <TextField
                          key={index}
                          label={item.label}
                          sx={item.sx}
                          size={item.size}
                          InputProps={item.InputProps}
                          type={item.type}
                          margin={item.margin}
                          {...item.register}
                          disabled={userDataLoading}
                          onKeyDown={(e) => {
                            if (item.name !== "mobile") {
                              return;
                            }
                            if (e.key === "." || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            if (item.type === "number") {
                              //@ts-ignore
                              e.target?.blur();
                            }
                          }}
                          error={!!errors[item.name]}
                          helperText={errors[item.name]?.message?.toString()}
                          InputLabelProps={item.InputLabelProps}
                        />
                      ))}

                    {isIOS &&
                      userFields.map((item: any, index: number) => (
                        <TextField
                          key={index}
                          label={item.label}
                          sx={item.sx}
                          size={item.size}
                          InputProps={item.InputProps}
                          type={item.type}
                          margin={item.margin}
                          {...item.register}
                          error={!!errors[item.name]}
                          helperText={errors[item.name]?.message?.toString()}
                          InputLabelProps={item.InputLabelProps}
                        />
                      ))}

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: 10 }}
                      sx={{ mt: 2, minWidth: "20%", borderRadius: 10 }}
                    >
                      {"Book Now"}
                    </Button>
                  </>
                )}

                {OTP_VERIFICATION && page === Screens.BOOKING_OTP && (
                  <BookingOTP
                    phoneCode={"+20"}
                    booking={booking}
                    guests={guests}
                    eventDeposit={venue.eventDeposit}
                    conceptID={venue.id}
                    setPage={setPage}
                  ></BookingOTP>
                )}

                {page === Screens.BOOKING_CONFIRMATION && (
                  <VerifyBooking
                    venue={venue}
                    phoneCode={"+20"}
                    booking={booking}
                    guests={guests}
                    eventDeposit={venue.eventDeposit}
                    childDeposit={childDeposit}
                    valetDeposit={isValet ? VALET_DEPOSIT : 0}
                    isValet={isValet}
                    setPage={setPage}
                  ></VerifyBooking>
                )}

                {page === Screens.BOOKING_STATUS && (
                  <BookingStatus
                    venue={venue}
                    guestEmail={booking.email}
                    setPage={setPage}
                  ></BookingStatus>
                )}
              </form>
            </div>

            {/* Action Buttons */}
            {page === Screens.BOOKING_FORM && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                style={{ borderRadius: 10 }}
                onClick={validateFirstPage}
                sx={{ mt: 2, width: "20%", borderRadius: 10 }}
              >
                {"Next"}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  else return <div></div>;
}

const styles = {
  counterButton: {
    borderRadius: "10%",
    backgroundColor: theme.palette.primary.dark,
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    color: "white",
  },
};

export default ReservationPage;
