import React from "react";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";

import {
  Avatar,
  Box,
  Button,
  Typography,
  Grid,
  createTheme,
} from "@mui/material";

import { Screens } from "../../constants/enums";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, getTimeSlotName } from "../../helpers/utils";
import Separator from "../Separator/Separator";
import { VALET_CHECKING } from "../../constants/Constants";
import lightThemeOptions from "../../theme/lightThemeOptions";

interface Props {
  venue: any;
  guestEmail: string;
  setPage: (page: number) => void;
}

const theme = createTheme(lightThemeOptions);

const BookingStatus: React.FC<Props> = ({ venue, guestEmail, setPage }) => {
  const timeSlots: any[] = venue ? venue.timeSlots : [];
  const booking: any = useSelector((state: any) => state.app.booking);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Status Section */}
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Avatar
              sx={{
                width: 60,
                height: 60,
                backgroundColor: "#ffdac4", // Set your custom background color
                // border: "2px solid #986840", // Set your border color
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                marginX: "auto", // Center horizontally
              }}
            >
              <UpdateOutlinedIcon fontSize="large" sx={{ color: "black" }} />
            </Avatar>

            <Typography component="p" gutterBottom sx={styles.message}>
              THANK YOU FOR YOUR RESERVATION!
            </Typography>
            <Typography component="p" sx={styles.description}>
              Our Team is finding you a table,
            </Typography>
            <Typography component="p" sx={styles.description}>
              you will receive an SMS to confirm
            </Typography>

            <Separator />
          </Grid>

          {/* Booking Details */}
          <Grid item xs={12}>
            <Typography>BOOKING DETAILS:</Typography>
            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Guest Name : &nbsp;</Typography>
              <Typography component="p" gutterBottom sx={styles.details}>
                {booking && booking.customerName
                  ? capitalizeFirstLetter(booking.customerName)
                  : ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Guest Mobile : &nbsp;</Typography>
              <Typography component="p" gutterBottom sx={styles.details}>
                {booking ? booking.customerPhone : ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Mail : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {guestEmail}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>
                Number of Guest(s) : &nbsp;
              </Typography>
              <Typography component="p" sx={styles.details}>
                {booking ? booking.accompaniedCount : "1"}
              </Typography>
            </Box>

            {booking.children && booking.children > 0 && (
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                }}
              >
                <Typography sx={styles.title}>
                  Number of Children : &nbsp;
                </Typography>
                <Typography component="p" sx={styles.details}>
                  {booking ? booking.accompaniedCount : "1"}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Date : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {booking ? booking.date : ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Time : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {booking && booking.timeSlots && booking.timeSlots.length > 0
                  ? getTimeSlotName(
                      timeSlots,
                      booking.timeSlots[0] ? booking.timeSlots[0] : ""
                    )
                  : ""}
              </Typography>
            </Box>

            {VALET_CHECKING && (
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                }}
              >
                <Typography sx={styles.title}>Valet : &nbsp;</Typography>
                <Typography component="p" sx={styles.details}>
                  {booking && booking.valetDeposit > 0
                    ? "Valet service requested."
                    : "Valet service not requested."}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Actions Section */}
        <Button
          variant="contained"
          sx={{
            color: "white",
            marginTop: 5,
            cursor: "pointer",
            borderRadius: "10px",
          }}
          onClick={(page) => setPage(Screens.BOOKING_FORM)}
        >
          Go Home
        </Button>
      </Box>
    </>
  );
};

const styles = {
  title: {
    color: "#737170",
  },
  details: {
    color: theme.palette.primary.dark,
  },
  message: {
    color: "#black",
    textAlign: "center",
    fontSize: "16px",
    letterSpacing: "0.64px",
    textTransform: "uppercase",
  },
  description: {
    color: theme.palette.primary.dark,
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
};
export default BookingStatus;
