import { invokeLambda } from "../helpers/utils";
import { LambdaNames } from "../constants/Constants";
export const GetUserService = async (phone_number:any) => {
  try {
    // Create the request body with the operationId and phone_number
    const requestBody = {
      operationId: 2,
      phone_number: phone_number,
    };
    const response = await invokeLambda(
      LambdaNames.getNotifications,
      requestBody
    );

    return response;
  } catch (error) {
    // Handle errors more informatively, log, or re-throw if appropriate
    console.log("Error retrieving user data:", error);
    // throw new Error('Failed to retrieve user data'); // Or custom error message
  }
};
