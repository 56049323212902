import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    // Egypt
    // primary: {
    //   light: "#FF853E",
    //   main: "#F9650E",
    //   dark: "#F9650E",
    //   contrastText: "#fff",
    // },
    // KSA
    primary: {
      light: "#73ad73",
      main: "#509950",
      dark: "#509950",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFFBF8",
      main: "#FFFBF8",
      dark: "#FFFBF8",
      contrastText: "#fff",
    },
    background: {
      paper: "#FFFBF8",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
