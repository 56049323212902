export const EndPoints = {
  // EGYPT
  // TimeSlots:
  //   "https://ygu2qok5ltcgynztxzg33ia36q0llpnd.lambda-url.us-east-2.on.aws/",

  // KSA
  TimeSlots:
    "https://enasjfwqww67yatqdazhywdw3y0xkltz.lambda-url.us-east-2.on.aws/",
  sendOTP: "https://sms.anyware.software/cequens-sms/",
};
export const LambdaNames = {
  Venues: "hospitalityOfflineListVenues",
  TimeSlots: "hospitalityOfflineListSlots",
  ParentConcepts: "hospitalityOfflineGetParentConcepts",
  getBookings: "hospitalityOfflineListBookings",
  createBooking: "hospitalityOfflineCreateBooking",
  getNotifications: "hospitalityOfflineGetNotifications",
  pushToken: "hospitalityOfflineStorePushToken",
  createReview: "hospitalityOfflineCreateReview",
  updateReview: "hospitalityOfflineUpdateReview",
  ExpiredBooking: "hospitalityOfflineExpiredBooking",
  UpdateUser: "hospitalityOfflineUpdateUser",
};

export const VALET_DEPOSIT = 0;
export const EMAIL_TEMPLATE = "TaghmisaPending";

// EGYPT
// export const IMAGE = "taghmisa.webp";
// export const CURRENCY = "EGP";
// export const operationId = "0";
// export const SHOW_BANNER = true;
// export const OTP_VERIFICATION = true;
// export const AUTO_ALLOCATION = false;
// export const ADD_GUESTS = true;
// export const VALET_CHECKING = false;
// export const SEARCH_PHONE_NUMBER = false;
// export const CHILD_CHECKING = false;
// export const COGNITO_IDENTITYPOOLID =
//   "us-east-2:3a1c8c89-d654-47e1-bd78-b80ba0c71835";
// export const HIDE_PREVIEW = false;
// export const PAYMENT_LINK =
//   "https://reservation-payment.vercel.app/reservations/";
// export const NOTES = [];
// export const TERMS_AND_CONDITIONS = [
//   "* Minimum spend is 550++ On Thursdays and Fridays",
//   "* Reservations are cancelled in case of 30 mins no show.",
// ];

// KSA
export const IMAGE = "taghmisaksa.webp";
export const CURRENCY = "SAR";
export const operationId = "1";
export const SHOW_BANNER = false;
export const OTP_VERIFICATION = false;
export const AUTO_ALLOCATION = true;
export const ADD_GUESTS = false;
export const VALET_CHECKING = false;
export const SEARCH_PHONE_NUMBER = true;
export const COGNITO_IDENTITYPOOLID =
  "us-east-2:a7591520-a30f-4773-bbf4-7f758b70b4e6";
export const CHILD_CHECKING = true;
export const HIDE_PREVIEW = true;
export const PAYMENT_LINK =
  "https://reservation-payment.vercel.app/reservations/";
export const NOTES = [
  "* The booking deposit for Adult is 150 SR, and for child is 75 SR Excluded VAT.",
  "* Amount paid will be deducted from invoice.",
  "* Table seating max 90 minutes.",
  "* Can't add more people later.",
];
export const TERMS_AND_CONDITIONS = [
  "* This amount not refundable and will be deducted from your total invoice",
];
